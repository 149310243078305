














import { Component, Prop, Vue } from 'vue-property-decorator';



@Component
export default class Category extends Vue {
  @Prop() private title!: string;
  @Prop() private apps!: any;

  getImgUrl(path: any) {
      return require(path);
  }

  goToLink(href: any) {
      window.open(href, '_blank');
  }

}
