























import { Component, Vue } from 'vue-property-decorator';
import Category from './components/Category.vue';

@Component({
  components: {
    Category,
  },
})
export default class App extends Vue {
  serverTitle = 'QA';
  cssApps = [
    {
        name: 'CSS Kitchen',
        screenshot: require('@/assets/screenshots/csskitchen.png'),
        link: 'apps/csskitchen'
    },
    {
        name: 'CSS Master Kitchen',
        screenshot: require('@/assets/screenshots/cssmasterkitchen.png'),
        link: 'apps/cssmasterkitchen'
    },
    {
      name: 'CSS Cloud',
        screenshot: require('@/assets/screenshots/csscloud.png'),
        link: 'apps/csscloud'
    },
    {
        name: 'CSS Queue',
        screenshot: require('@/assets/screenshots/cssqueue.png'),
        link: 'apps/cssqueue'
    },
    {
        name: 'Tampines eMenu',
        screenshot: require('@/assets/screenshots/cssemenu.png'),
        link: 'apps/cssemenu'
    },
    {
        name: 'CENTRAL eMenu',
        screenshot: require('@/assets/screenshots/cssemenu-chinatown.png'),
        link: 'apps/cssemenu-chinatown'
    },
  ]

  posApps = [
    {
        name: 'RETAIL POS',
        screenshot: require('@/assets/screenshots/retailpos.png'),
        link: 'apps/retailpos'
    },
    {
        name: 'RETAIL HQ4',
        screenshot: require('@/assets/screenshots/retailhq4.png'),
        link: 'apps/retailhq4'
    },
    {
        name: 'FNB POS',
        screenshot: require('@/assets/screenshots/fnbpos.png'),
        link: 'apps/fnbpos'
    },
    {
        name: 'FNB HQ4',
        screenshot: require('@/assets/screenshots/fnbhq4.png'),
        link: 'apps/fnbhq4'
    },
    {
        name: 'Kitchen Display (KDS)',
        screenshot: require('@/assets/screenshots/kitchendisplay.png'),
        link: 'apps/kitchendisplay'
    },
    {
        name: 'eWaitress',
        screenshot: require('@/assets/screenshots/ewaitress.png'),
        link: 'apps/ewaitress'
    },
    {
        name: 'eMenu',
        screenshot: require('@/assets/screenshots/fnbemenu.jpg'),
        link: 'apps/fnbemenu/#/?data=157848728854048'
    }
  ]

  koiApps = [
    {
        name: 'KOI HR',
        screenshot: require('@/assets/screenshots/koihr.png'),
        link: 'apps/koihr'
    },
    {
        name: 'KOI Employee',
        screenshot: require('@/assets/screenshots/koiemployee.png'),
        link: 'apps/koiemployee'
    },
    {
        name: 'KOI Appraisal',
        screenshot: require('@/assets/screenshots/koiappraisal.png'),
        link: 'apps/koiappraisal'
    }
  ]

  dysonApps = [
    {
        name: 'Dyson HR (Field Force Management)',
        screenshot: require('@/assets/screenshots/dysonhr.png'),
        link: 'apps/dysonhr'
    },
    {
        name: 'Dyson Dashboard',
        screenshot: require('@/assets/screenshots/dysondashboard.png'),
        link: 'apps/dysondashboard'
    },
    {
        name: 'Dyson SuperHQ',
        screenshot: require('@/assets/screenshots/dysonsuperhq.png'),
        link: 'apps/dysonsuperhq'
    }
  ]

  others = [
    {
        name: 'FS Servicing',
        screenshot: require('@/assets/screenshots/fs-servicing.png'),
        link: 'apps/fs-servicing'
    },
    {
        name: 'Animal Planet Kiosk',
        screenshot: require('@/assets/screenshots/animalplanetkiosk.png'),
        link: 'apps/animalplanetkiosk'
    },
    {
        name: 'Animal Planet CMS',
        screenshot: require('@/assets/placeholder.png'),
        link: 'apps/animalplanetcms'
    }
  ]
}
